<template>
  <div class="max-w-xl mx-auto px-2 my-8 rounded">
    <form @submit.prevent="dataSubmit">
      <div class="w-full flex gap-8 bg-white p-4 rounded-md shadow-md">
        <div
          @click="$router.go(-1)"
          class="text-brand rounded-md py-1.5 px-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
        </div>
        <div
          class="flex flex-col justify-center items-center mx-auto gap-8 mt-8 md:mt-0"
        >
          <h2 class="text-2xl text-brand">Create User</h2>
          <div class="block w-full relative">
            <Input
              type="text"
              v-model="form.name"
              class="w-full focus:outline-none border text-center"
              placeholder="Full Name"
              autocomplete="off"
              required
            />
          </div>
          <div class="block w-full relative">
            <Input
              type="number"
              v-model="form.phone"
              class="w-full focus:outline-none border text-center"
              placeholder="Enter Your Phone Number"
              autocomplete="off"
              oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxLength="11"
              required
            />
          </div>
          <div class="block w-full relative">
            <Select
              v-model="form.gender"
              class="w-full focus:outline-none border text-center"
              autocomplete="off"
              required
            >
              <option value="">Select Gender</option>
              <option value="1">Male</option>
              <option value="2">Female</option>
            </Select>
          </div>

          <div class="block w-full relative">
            <text-area
              type="text"
              v-model="form.designation"
              class="w-full focus:outline-none border text-left p-2"
              placeholder="User Designation"
            ></text-area>
          </div>

          <div class="block w-full">
            <InputText
              v-model="form.password"
              password-eye
              type="password"
              class="w-full focus:outline-none text-center"
              placeholder="Set Password"
              autocomplete="off"
            />
          </div>

          <div>
            <div
              class="relative flex justify-center"
              :class="{ 'opacity-100': form.phone.length < 11 }"
            >
              <button
                type="submit"
                class="bg-brand text-white py-1.5 px-2 rounded-md flex items-center"
              >
                <spinner v-if="loading" />
                submit
              </button>
              <div v-if="form.phone.length < 11">
                <div
                  class="absolute inset-0 bg-green-700/10 z-50 flex items-center justify-end px-4"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Input from "../../../components/input-new.vue";
import TextArea from "../../../components/input-text-area.vue";
import Spinner from "../../../components/spinner.vue";
import Select from "../../../components/select.vue";
import InputText from "../../../components/input-text.vue";
// import ShowError from "../../../components/show-error.vue";
export default {
  components: {
    Input,
    Spinner,
    Select,
    InputText,
    TextArea,
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        gender: "",
        designation: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    searchUser() {
      // return this.$store.state.join.confirm.user_genesis;
    },
  },

  watch: {
    "$store.state.join.user_edit"(data) {
      console.log("", data);
    },
  },

  methods: {
    dataSubmit() {
      this.$store
        .dispatch("users/userCreate", {
          data: {
            name: this.form.name,
            phone: this.form.phone,
            gender: this.form.gender,
            password: this.form.password,
          },
        })
        .then(() => {
          this.$toast.success(`User Successfully Created !!!`, {
            position: "top",
            duration: 5000,
          });
          this.$router.push("/admin/users");
        })
        .finally(() => {});
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
